<template>
  <div :class="$style.order_cart_list">
    <div v-for="item in formattedCartList" :key="item.cartDetail.sku.document_id" :class="$style.item">
      <OrderSideCartListItem
        v-if="
          (!item.cartDetail.embroideries || item.cartDetail.embroideries.length <= 0) &&
          (!item.cartDetail.gifts || item.cartDetail.gifts.length <= 0) &&
          (!item.cartDetail.group_wrappings || item.cartDetail.group_wrappings.length <= 0)
        "
        :cart-detail="item.cartDetail"
        :can-wrapping="item.wrapping.length > 0"
        :is-confirm="isConfirm"
        :item-border="itemsBorder"
        @on-add-cart-item="
          addCartItemOld(
            item.cartDetail.product.document_id,
            item.cartDetail.sku.document_id,
            item.cartDetail.product.cart_only_gift,
            item.cartDetail.quantity,
            item.cartDetail.sku.site_id,
            item.cartDetail.sku.is_reservation,
            item.cartDetail.sku.net_stock_quantity,
            item.cartDetail.sku.net_stock_quantity_limit,
            item.cartDetail.product.order_quantity_limit,
            item.cartDetail.product.order_quantity_limit_type,
          )
        "
        @on-reduce-cart-item="
          reduceCartItem({
            skuId: item.cartDetail.sku.document_id,
            skuAmt: item.cartDetail.quantity,
            groupWrappedUniqId: item.cartDetail.group_wrapped_uniq_id,
          })
        "
        @on-open-select-gift-modal="openSelectGiftModal(item.cartDetail.sku.document_id)"
      />

      <div
        v-if="item.cartDetail.group_wrappings && item.cartDetail.group_wrappings.length >= 1"
        :class="$style.purchase_options_list"
      >
        <OrderSideCartListItem
          v-for="groupWrapping in item.cartDetail.group_wrappings"
          :key="groupWrapping.group_wrapping_uniq_id"
          :cart-detail="item.cartDetail"
          :can-wrapping="false"
          :is-confirm="isConfirm"
          :item-border="itemsBorder"
          :group-wrapping-skus="groupWrappingSkus(groupWrapping)"
          has-set-wrapping
          @on-add-cart-item="
            addCartItem({
              productId: item.cartDetail.product.document_id,
              skuId: item.cartDetail.sku.document_id,
              cart_only_gift: item.cartDetail.product.cart_only_gift,
              quantity: item.cartDetail.quantity,
              site_id: item.cartDetail.sku.site_id,
              is_reservation: item.cartDetail.sku.is_reservation,
              net_stock_quantity: item.cartDetail.sku.net_stock_quantity,
              net_stock_quantity_limit: item.cartDetail.sku.net_stock_quantity_limit,
              order_quantity_limit: item.cartDetail.product.order_quantity_limit,
              order_quantity_limit_type: item.cartDetail.product.order_quantity_limit_type,
              groupWrapping,
            })
          "
          @on-reduce-cart-item="
            reduceCartItem({
              skuId: item.cartDetail.sku.document_id,
              skuAmt: item.cartDetail.quantity,
              groupWrapping,
              groupWrappedUniqId: groupWrappingUniqId(groupWrapping),
            })
          "
          @on-init-select-gift="initSelectGift(item.cartDetail.sku.document_id)"
        />
      </div>

      <div
        v-if="item.cartDetail.embroideries && item.cartDetail.embroideries.length >= 1"
        :class="$style.purchase_options_list"
      >
        <OrderSideCartListItem
          v-if="item.cartDetail.count > 0 && !item.cartDetail.gifts?.length"
          :cart-detail="item.cartDetail"
          :is-confirm="isConfirm"
          :item-border="itemsBorder"
          @on-add-cart-item="
            addCartItemOld(
              item.cartDetail.product.document_id,
              item.cartDetail.sku.document_id,
              item.cartDetail.product.cart_only_gift,
              item.cartDetail.quantity,
              item.cartDetail.sku.site_id,
              item.cartDetail.sku.is_reservation,
              item.cartDetail.sku.net_stock_quantity,
              item.cartDetail.sku.net_stock_quantity_limit,
              item.cartDetail.product.order_quantity_limit,
              item.cartDetail.product.order_quantity_limit_type,
            )
          "
          @on-reduce-cart-item="
            reduceCartItem({
              skuId: item.cartDetail.sku.document_id,
              skuAmt: item.cartDetail.quantity,
            })
          "
        />

        <OrderSideCartListItem
          v-for="embroidery in item.cartDetail.embroideries"
          :key="embroidery.embroidered_sku_id"
          :cart-detail="item.cartDetail"
          :embroidery="embroidery"
          :is-confirm="isConfirm"
          :item-border="itemsBorder"
          @on-add-cart-item="
            addCartItem({
              productId: item.cartDetail.product.document_id,
              skuId: item.cartDetail.sku.document_id,
              cart_only_gift: item.cartDetail.product.cart_only_gift,
              quantity: item.cartDetail.quantity,
              site_id: item.cartDetail.sku.site_id,
              is_reservation: item.cartDetail.sku.is_reservation,
              net_stock_quantity: item.cartDetail.sku.net_stock_quantity,
              net_stock_quantity_limit: item.cartDetail.sku.net_stock_quantity_limit,
              order_quantity_limit: item.cartDetail.product.order_quantity_limit,
              order_quantity_limit_type: item.cartDetail.product.order_quantity_limit_type,
              embroidery,
            })
          "
          @on-reduce-cart-item="
            reduceCartItem({
              skuId: item.cartDetail.sku.document_id,
              skuAmt: item.cartDetail.quantity,
              embroidery,
            })
          "
        />
      </div>

      <div v-if="item.cartDetail.gifts && item.cartDetail.gifts.length >= 1" :class="$style.purchase_options_list">
        <OrderSideCartListItem
          v-if="item.cartDetail.count > 0"
          :cart-detail="item.cartDetail"
          :can-wrapping="item.wrapping.length > 0"
          :is-confirm="isConfirm"
          :item-border="itemsBorder"
          @on-add-cart-item="
            addCartItemOld(
              item.cartDetail.product.document_id,
              item.cartDetail.sku.document_id,
              item.cartDetail.product.cart_only_gift,
              item.cartDetail.quantity,
              item.cartDetail.sku.site_id,
              item.cartDetail.sku.is_reservation,
              item.cartDetail.sku.net_stock_quantity,
              item.cartDetail.sku.net_stock_quantity_limit,
              item.cartDetail.product.order_quantity_limit,
              item.cartDetail.product.order_quantity_limit_type,
            )
          "
          @on-reduce-cart-item="
            reduceCartItem({
              skuId: item.cartDetail.sku.document_id,
              skuAmt: item.cartDetail.quantity,
            })
          "
          @on-open-select-gift-modal="openSelectGiftModal(item.cartDetail.sku.document_id, true)"
        />

        <OrderSideCartListItem
          v-for="gift in item.cartDetail.gifts"
          :key="gift.sku_code"
          :cart-detail="item.cartDetail"
          :can-wrapping="item.wrapping.length > 0"
          :embroidery="gift.embroidery"
          :gift="gift"
          :is-confirm="isConfirm"
          :item-border="itemsBorder"
          has-set-wrapping
          @on-add-cart-item="
            addCartItemOld(
              item.cartDetail.product.document_id,
              item.cartDetail.sku.document_id,
              item.cartDetail.product.cart_only_gift,
              item.cartDetail.quantity,
              item.cartDetail.sku.site_id,
              item.cartDetail.sku.is_reservation,
              item.cartDetail.sku.net_stock_quantity,
              item.cartDetail.sku.net_stock_quantity_limit,
              item.cartDetail.product.order_quantity_limit,
              item.cartDetail.product.order_quantity_limit_type,
              gift,
              gift.embroidery,
            )
          "
          @on-reduce-cart-item="
            reduceCartItem({
              skuId: item.cartDetail.sku.document_id,
              skuAmt: item.cartDetail.quantity,
              gift,
            })
          "
          @on-init-select-gift="initSelectGift(item.cartDetail.sku.document_id)"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { CartGift, CartEmbroidery, CartItem } from "@tential/ec-gql-schema/models/order"
import { SkuWithGift, SkuWithEmbroidery, SkuWithGroupWrapping, ProductAndSku } from "@tential/ec-gql-schema/types/cart"
import { errorMessage } from "@tential/ec-gql-schema/utils/functions/error"
import { useConfirmDialog } from "~/composables/useConfirmDialog"
import { useCart } from "~/composables/useCart"
import { useToast } from "~/state"
import { CartListWithGift } from "~/types/cart"
import { checkGiftItemLimit } from "~/utils/functions/validate"

const props = withDefaults(
  defineProps<{
    /** カートデータ */
    cartList: CartListWithGift[]
    /** サブスク */
    isSubscription?: boolean
    /** 確認画面かどうか */
    isConfirm?: boolean
    /** 交換クーポンを使用しているか */
    isExchangeCoupon?: boolean
    /** ListItemの境界線を表示するかどうか */
    itemsBorder?: boolean
  }>(),
  {
    isSubscription: false,
    isConfirm: false,
    isExchangeCoupon: false,
    itemsBorder: false,
  },
)

type Emits = {
  (e: "openSelectGift", sku_id: string, isNotGift: boolean): void
  (e: "initSelectGift", sku_id: string): void
}
const emit = defineEmits<Emits>()

const { proceed } = useConfirmDialog()
const toast = useToast()
const cart = useCart()
const { groupWrappingUniqId } = useGift()
const gtag = useGtag()
const config = useRuntimeConfig()
const addBtnDisplay = ref<boolean>(true)
/** ギフト商品＆ラッピング済み商品を除いた商品の数量 */
const notGiftSkuQuantity = computed(() => {
  return cart.cartLine.value.reduce((total, cartItem) => {
    if (!cartItem.product.is_gift) return total + cartItem.count
    return total
  }, 0)
})

const formattedCartList = computed(() => {
  // まとめてラッピングではない場合はそのまま返す
  if (!props.cartList.some((item) => item.cartDetail.group_wrapped_uniq_id)) return props.cartList
  // まとめてラッピングは内包している商品を除いたリストを返す
  return props.cartList.filter((item) => !item.cartDetail.group_wrapped_uniq_id)
})

const groupWrappingSkus = (groupWrapping: SkuWithGroupWrapping): ProductAndSku[] => {
  return props.cartList
    .filter((cartItem) => cartItem.cartDetail.group_wrapped_uniq_id === groupWrappingUniqId(groupWrapping))
    .map((cartItem) => cartItem.cartDetail)
}

const reduceCartItem = async (args: {
  skuId: string
  skuAmt: number
  gift?: SkuWithGift
  embroidery?: SkuWithEmbroidery
  groupWrapping?: SkuWithGroupWrapping
  groupWrappedUniqId?: string
}): Promise<void> => {
  const { skuId, skuAmt, gift, embroidery, groupWrapping, groupWrappedUniqId } = args
  if (skuAmt === 1) {
    if (!(await proceed("本当に削除してよろしいですか？"))) return
  }

  const removeItems = []
  if (groupWrapping) {
    // まとめてラッピングの場合は内包している商品も合わせて削除
    for (const wrappingSkuId of groupWrapping.wrapping_sku_ids) {
      removeItems.push({ skuId: wrappingSkuId, groupWrappedUniqId })
    }
  }
  removeItems.push({ skuId, gift, embroidery, groupWrapping, groupWrappedUniqId })

  // カートに購入点数制限がある商品があったら同時に削除
  if (cart.cartLine.value.length > 0) {
    for (const item of cart.cartLine.value) {
      if (item.product.order_quantity_limit && item.product.order_quantity_limit > 0) {
        if (item.product.order_quantity_limit_type === "order") {
          // 数量が購入点数制限以上の場合
          if (item.quantity > item.product.order_quantity_limit) {
            toast?.showErrorToasted(`${item.product.name}は、購入点数上限を超えたためカートから削除されました`)
            removeItems.push({ skuId: item.sku.document_id })
          }
        } else if (item.product.order_quantity_limit_type === "not_gift_sku") {
          // 数量がカート個数*購入点数制限以上の場合
          if (item.quantity > item.product.order_quantity_limit * notGiftSkuQuantity.value) {
            toast?.showErrorToasted(`${item.product.name}は、購入点数上限を超えたためカートから削除されました`)
            removeItems.push({ skuId: item.sku.document_id })
          }
        }
      }
    }
  }
  await cart.removeCartMultiItem(removeItems)
}

/**
 * @deprecated [非推奨] addCartItem を使用してください
 */
const addCartItemOld = async (
  productId: string,
  skuId: string,
  cart_only_gift: boolean,
  quantity: number,
  site_id?: string,
  is_reservation?: boolean,
  net_stock_quantity?: number,
  net_stock_quantity_limit?: number,
  order_quantity_limit?: number,
  order_quantity_limit_type?: string,
  gift?: SkuWithGift,
  embroidery?: SkuWithGift["embroidery"],
  groupWrapping?: SkuWithGroupWrapping,
): Promise<void> => {
  await addCartItem({
    productId,
    skuId,
    cart_only_gift,
    quantity,
    site_id,
    is_reservation,
    net_stock_quantity,
    net_stock_quantity_limit,
    order_quantity_limit,
    order_quantity_limit_type,
    gift,
    embroidery,
    groupWrapping,
  })
}

// カートから追加
const addCartItem = async (args: {
  productId: string
  skuId: string
  cart_only_gift: boolean
  quantity: number
  site_id?: string
  is_reservation?: boolean
  net_stock_quantity?: number
  net_stock_quantity_limit?: number
  order_quantity_limit?: number
  order_quantity_limit_type?: string
  gift?: SkuWithGift
  embroidery?: SkuWithGift["embroidery"]
  groupWrapping?: SkuWithGroupWrapping
}) => {
  const {
    productId,
    skuId,
    cart_only_gift,
    quantity,
    site_id,
    is_reservation,
    net_stock_quantity,
    net_stock_quantity_limit,
    order_quantity_limit,
    order_quantity_limit_type,
    gift,
    embroidery,
    groupWrapping,
  } = args
  if (props.isExchangeCoupon === true) return
  if (props.isSubscription) {
    // store.dispatch("addSubscriptionCartList", {
    //   productId: _productId,
    //   skuId: _skuId,
    //   siteId: _site_id,
    //   subscriptionItemId: _subscriptionItemId,
    //   isReservation: _is_reservation,
    // })
  } else {
    try {
      // 連打防止フラグ
      addBtnDisplay.value = false
      // 購入点数制限がある商品の点数チェック
      if (quantity && order_quantity_limit && order_quantity_limit > 0 && order_quantity_limit_type) {
        if (order_quantity_limit_type === "order") {
          // 数量が購入点数制限以上の場合はエラー
          if (quantity >= order_quantity_limit) {
            throw new Error(`この商品は、1回のご注文につき、${order_quantity_limit}個まで購入できます`)
          }
        } else if (order_quantity_limit_type === "not_gift_sku") {
          // 数量がカート個数*購入点数制限以上の場合はエラー
          if (quantity >= order_quantity_limit * notGiftSkuQuantity.value) {
            throw new Error(
              `この商品は、商品1点（ラッピング袋・カードを除く）につき、${order_quantity_limit}個まで購入できます`,
            )
          }
        }
      }

      // ギフト商品の購入上限を超えている場合はエラーを表示
      if (checkGiftItemLimit(cart.cartLine.value, cart_only_gift, 1)) {
        throw new Error("単品でのギフト資材は、1回のご注文で最大3点までしかカートに追加できません")
      }

      if (is_reservation && quantity && Number(net_stock_quantity) - quantity < Number(net_stock_quantity_limit)) {
        const availableQuantity =
          (net_stock_quantity ?? 0) - (net_stock_quantity_limit ?? 0) + 1 < 0
            ? 0
            : (net_stock_quantity ?? 0) - (net_stock_quantity_limit ?? 0) + 1
        throw new Error(`予約購入できる個数は${availableQuantity}個までです。`)
      }
      if (groupWrapping) {
        // まとめてラッピングの場合は内包している商品も合わせて追加
        const cartItems: CartItem[] = []
        for (const wrappingSkuId of groupWrapping.wrapping_sku_ids) {
          const wrappingSku = props.cartList.find((item) => item.cartDetail.sku.document_id === wrappingSkuId)
          if (wrappingSku) {
            cartItems.push({
              product_id: wrappingSku.cartDetail.product.document_id,
              product_name: wrappingSku.cartDetail.product.name,
              sku_id: wrappingSkuId,
              sku_name: wrappingSku.cartDetail.sku.name,
              site_id,
              is_reservation: wrappingSku.cartDetail.sku.is_reservation,
              material: wrappingSku.cartDetail.product.material,
              net_stock_quantity: wrappingSku.cartDetail.sku.net_stock_quantity,
              net_stock_quantity_limit: wrappingSku.cartDetail.sku.net_stock_quantity_limit,
              group_wrapped_uniq_id: groupWrappingUniqId(groupWrapping),
            })
          }
        }
        cartItems.push({
          product_id: productId,
          sku_id: skuId,
          site_id,
          is_reservation,
          net_stock_quantity,
          net_stock_quantity_limit,
          gift: gift ? formatGift(gift) : undefined,
          embroidery: embroidery ? formatEmbroidery(embroidery) : undefined,
          group_wrapping: {
            wrapping_sku_ids: groupWrapping.wrapping_sku_ids,
            gift: groupWrapping.gift,
          },
        })
        await cart.addCartMultiItem(cartItems, productId)
      } else {
        await cart.addCartItem({
          product_id: productId,
          sku_id: skuId,
          site_id,
          is_reservation,
          net_stock_quantity,
          net_stock_quantity_limit,
          gift: gift ? formatGift(gift) : undefined,
          embroidery: embroidery ? formatEmbroidery(embroidery) : undefined,
        })
      }
    } catch (e) {
      const error = errorMessage(e)
      toast?.showErrorToasted(error)
    } finally {
      addBtnDisplay.value = true
    }
  }
  gaPush(skuId)
}

const gaPush = (_skuId: string) => {
  if (config.public.APP_ENV === "production") {
    gtag("event", "add_to_cart", {
      currency: "JPY",
      items: [
        {
          item_id: _skuId,
        },
      ],
    })
  }
}

const formatGift = (gift: SkuWithGift): CartGift => {
  return {
    sku_id: gift.sku_id,
    message: gift.message,
    product_name: gift.product_name,
    sku_name: gift.sku_name,
    sku_code: gift.sku_code,
    size: gift.size,
    price: gift.price,
    img: gift.img,
    message_img: gift.message_img,
    message_price: gift.message_price,
    message_sku_code: gift.message_sku_code,
    message_sku_id: gift.message_sku_id,
    card: gift.card,
    card_img: gift.card_img,
    card_price: gift.card_price,
    card_sku_code: gift.card_sku_code,
    card_sku_id: gift.card_sku_id,
    product_id: gift.product_id,
  }
}

const openSelectGiftModal = (skuId: string, isNotGift: boolean = false): void => {
  emit("openSelectGift", skuId, isNotGift)
}

const initSelectGift = (skuId: string): void => {
  emit("initSelectGift", skuId)
}

const formatEmbroidery = (embroidery: SkuWithGift["embroidery"]): CartEmbroidery => {
  const formatEmbroidery = {
    text: embroidery.text,
    position: embroidery.position,
    color: embroidery.color,
    font: embroidery.font,
    price: embroidery.price,
  }
  return formatEmbroidery
}
</script>

<style scoped module lang="scss">
.order_cart_list {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  padding: 1.25rem 0;
  .item {
    flex-wrap: wrap;
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    .purchase_options_list {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 1.25rem;
    }
  }
}
</style>
